import { makeStyles } from '@material-ui/core';
import {
  CremaTheme,
} from 'services/context/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    appMain: {
      height: '100vh',
      display: 'flex',
      position: 'relative',
      paddingTop: 56,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 70,
      },
      '&.appMainFooter': {
        '& $mainContainer': {},
        '& $mainContainerFull': {},
      },
      '&.appMainFixedFooter': {
        paddingBottom: 48,
        [theme.breakpoints.up('xl')]: {
          paddingBottom: 58,
        },
        '& .scrollAppSidebar': {
          height: 'calc(100vh - 260px) !important',
          [theme.breakpoints.up('xl')]: {
            height: 'calc(100vh - 300px) !important',
          },
        },
        '& $mainContainer': {
          paddingBottom: 1,
        },
        '& $mainContainerFull': {},
      },
    },
    mainContent: {
      flex: 1,
      display: 'flex',
    },
    mainContainer: {
      width: 'calc(100vw - 19rem)',
      paddingBottom: 10,
      [theme.breakpoints.up('xl')]: {
        width: 'calc(100vw - 22.8rem)',
      },
      '& > .scrollbar-container': {
        padding: '20px 20px 0',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          padding: '30px 32px 0',
        },
      },
    },
    mainContainerFull: {
      width: '100vw',
      paddingBottom: 10,
      '& > .scrollbar-container': {
        padding: '20px 20px 0',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          padding: '30px 32px 0',
        },
      },
    },
  };
});
export default useStyles;

import React, { useContext } from 'react';
import { Icon, ListItem, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import IntlMessages from 'services/localizer/IntlMessages';
import useStyles from './LogoutItem.style';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { NavItemProps } from './NavItemProps';
import AppContext from 'services/context/AppContext';
import NavLink from 'components/AppNavLink';
import { AuthService } from 'services/auth/authService';
import AppContextPropsType from 'services/context/AppContextPropsType';

interface LogoutItemProps extends RouteComponentProps<any> {
  item: NavItemProps;
  level: number;
}

const LogoutItem: React.FC<LogoutItemProps> = ({
  item,
  level,
}) => {
  const classes = useStyles({ level });
  const { isLoggedIn } = useContext<AppContextPropsType>(AppContext);

  return (
    <Box onClick={() => {
      if (isLoggedIn) {
        AuthService.instance().signOut();
      }
    }}>
      <ListItem
        button
        component={NavLink}
        className={clsx(classes.navItem, 'nav-item', classes.bottomNav)}>
        {item.icon && (
          <Box component='span' mr={6}>
            <Icon
              className={clsx(classes.listIcon, 'nav-item-icon')}
              color='action'>
              {item.icon}
            </Icon>
          </Box>
        )}
        <ListItemText
          primary={<IntlMessages id={item.messageId} />}
          classes={{ primary: 'nav-item-text' }}
        />
      </ListItem>
    </Box>
  );
};

export default withRouter(LogoutItem);

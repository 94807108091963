import { makeStyles } from '@material-ui/core/styles';
import {
  CremaTheme,
} from 'services/context/AppContextPropsType';
import R from 'res/R';
import Color from 'color';

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    navItem: {
      height: 64,
      fontWeight: 700,
      width: '19rem',
      [theme.breakpoints.up('xl')]: {
        width: '21.6rem',
      },
      cursor: 'pointer',
      textDecoration: 'none !important',
      paddingLeft:
        theme.direction === 'ltr'
          ? (props: {level: number}) => 24 + 50 * props.level
          : 12,
      paddingRight:
        theme.direction === 'rtl'
          ? (props: {level: number}) => 24 + 50 * props.level
          : 12,
      '&.nav-item-header': {
        textTransform: 'uppercase',
      },
      '&.active': {
        backgroundColor: Color(R.colors.virbacLightBrown).alpha(0.2).rgb().toString(),
        pointerEvents: 'none',
        transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
        '& .nav-item-text': {
          color: R.colors.white,
        },
        '& .nav-item-icon': {
          color: 'rgba(255,255,255,1)' + '!important',
        },
      },

      '&:hover, &:focus': {
        backgroundColor: Color(R.colors.virbacLightBrown).alpha(0.2).rgb().toString(),
        '& .nav-item-text': {
          ...R.typography.filledText,
          color: R.colors.virbacLightBrown,
          fontWeight: 'bold',
        },

        '& .nav-item-icon': {
          color: '#fff',
        },

        '& .nav-item-icon-arrow': {
          color: theme.palette.primary.main,
        },
      },
      '& .nav-item-icon': {
        color: theme.palette.sidebar.textColor,
      },
      '& .nav-item-text': {
        ...R.typography.filledText,
        color: R.colors.virbacLightBrown,
      },
    },
    '@media (max-width: 1919px)': {
      navItem: {
        height: 48,
        paddingLeft:
          theme.direction === 'ltr'
            ? (props: {level: number}) => 17 + 50 * props.level
            : 12,
        paddingRight:
          theme.direction === 'rtl'
            ? (props: {level: number}) => 17 + 50 * props.level
            : 12,

        '& .nav-item-text': {
          fontSize: 16,
        },
      },
    },
    listIcon: {
      fontSize: 22,
      [theme.breakpoints.up('xl')]: {
        fontSize: 24,
      },
    },
    bottomNav: {
      position: 'absolute',
      bottom: 0,
    },
  };
});
export default useStyles;

import { makeStyles } from '@material-ui/core/styles';
import R from 'res/R';
import {
  CremaTheme,
} from 'services/context/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    navItem: {
      height: 48,
      paddingLeft:
        theme.direction === 'ltr'
          ? (props: {level: number}) => 17 + 50 * props.level
          : 17,
      paddingRight:
        theme.direction === 'rtl'
          ? (props: {level: number}) => 17 + 50 * props.level
          : 17,
      width: '100%',

      [theme.breakpoints.up('xl')]: {
        height: 64,
        paddingLeft:
          theme.direction === 'ltr'
            ? (props: {level: number}) => 24 + 50 * props.level
            : 24,
        paddingRight:
          theme.direction === 'rtl'
            ? (props: {level: number}) => 24 + 50 * props.level
            : 24,
      },

      '& .nav-item-text': {
        ...R.typography.filledText,
        color: R.colors.virbacLightBrown,

        [theme.breakpoints.up('xl')]: {
          fontSize: 18,
        },
      },

      '& .nav-item-icon': {
        color: theme.palette.sidebar.textColor,
      },

      '& .nav-item-icon-arrow': {
        color: theme.palette.sidebar.textColor,
      },

      '&.open, &:hover, &:focus': {
        '& .nav-item-text': {
          ...R.typography.filledText,
          color: R.colors.virbacLightBrown,
          fontWeight: 'bold',
        },

        '& .nav-item-icon': {
          color: '#313541',
        },

        '& .nav-item-icon-arrow': {
          color: '#313541',
        },
      },
    },
    listItemText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    listIcon: {
      fontSize: 18,
      [theme.breakpoints.up('xl')]: {
        fontSize: 20,
      },
    },
  };
});
export default useStyles;

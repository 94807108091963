import React, { useContext, useEffect } from 'react';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import AppContext from 'services/context/AppContext';
import AppLocale from 'services/localizer/localization';
import { responsiveFontSizes } from '@material-ui/core';
import { useBreakPointDown } from 'infra/breakPoint';
import { useUrlSearchParams } from 'use-url-search-params';
import AppContextPropsType from 'services/context/AppContextPropsType';

const CremaThemeProvider: React.FC<React.ReactNode> = (props) => {
  const {
    theme,
    locale,
    updateTheme,
  } = useContext<AppContextPropsType>(AppContext);
  const { muiLocale } = AppLocale[locale.locale];
  const isBelowMd = useBreakPointDown('md');

  const initailValue: InitialType = {};
  const types: TypesType = {};
  const [ params ] = useUrlSearchParams(initailValue, types);

  useEffect(() => {
    const updateQuerySetting = () => {
      if (params.theme_mode) {
        if (updateTheme) {
          const currentTheme = { ...theme };
          currentTheme.palette.type = 'light';
          currentTheme.palette.background = {
            paper: '#FFFFFF',
            default: '#f3f4f6',
          };
          currentTheme.palette.text = {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.67)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
          };
          updateTheme(currentTheme);
        }
      }
    };
    updateQuerySetting();
  }, [ params.theme_mode, updateTheme ]);

  useEffect(() => {
    const updateQuerySetting = () => {
      document.body.setAttribute('dir', 'ltr');
    };
    updateQuerySetting();
  }, [ ]);

  useEffect(() => {
    const updateQuerySetting = () => {
      if (params.theme_style) {
        if (isBelowMd) {
          // @ts-ignore
          theme.overrides.MuiCard.root.borderRadius = 20;
          // @ts-ignore
          theme.overrides.MuiToggleButton.root.borderRadius = 20;
        } else {
          // @ts-ignore
          theme.overrides.MuiCard.root.borderRadius = 30;
          // @ts-ignore
          theme.overrides.MuiToggleButton.root.borderRadius = 30;
        }
        // @ts-ignore
        theme.overrides.MuiButton.root.borderRadius = 30;
        // @ts-ignore
        theme.overrides.MuiCardLg.root.borderRadius = 50;
        updateTheme!(theme);
      }
    };
    updateQuerySetting();
  }, [ params.theme_style, theme, isBelowMd, updateTheme ]);

  return (
    <ThemeProvider
      theme={responsiveFontSizes(createMuiTheme(theme, muiLocale))}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {props.children}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default React.memo(CremaThemeProvider);

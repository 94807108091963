import React, {
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { matchRoutes } from 'react-router-config';
import AppContext from 'services/context/AppContext';
import Loader from 'components/Loader';
import AppContextPropsType from 'services/context/AppContextPropsType';
import { AuthUser } from 'services/auth/authUser';
import { AuthService } from './authService';

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { routes, initialPath, setInitialPath } = useContext<AppContextPropsType>(AppContext);

  const [ token, loading ] = useAuthToken();
  const currentRoute = matchRoutes(routes, pathname)[0].route;

  useEffect(() => {
    function setInitPath() {
      if (
        initialPath === '/' &&
        [
          '/signin',
        ].indexOf(pathname) === -1
      ) {
        if (setInitialPath) {
          setInitialPath(pathname + search);
        }
      }
    }

    setInitPath();
  }, [ initialPath, loading, pathname, token, search ]);

  useEffect(() => {
    if (!loading) {
      if (!token && currentRoute.auth && currentRoute.auth.length >= 1) {
        history.push('/signin');
      } else if (
        (pathname === '/signin') && token ) {
        // @ts-ignore
        if (pathname === '/') {
          history.push('/dashboards/customerManagement');
        }
        // @ts-ignore
        else if (initialPath !== '/signin') {
          history.push(initialPath || '/');
        } else {
          history.push('/dashboards/customerManagement');
        }
      }
    }
  }, [ token, loading, pathname, initialPath, currentRoute.auth, history ]);

  return loading ? <Loader /> : <>{children}</>;
};

const useAuthToken = () => {
  const [ loading, setLoading ] = useState(true);
  const [ token, setToken ] = useState<string | null>(null);
  const { isLoggedIn, setIsLoggedIn } = useContext<AppContextPropsType>(AppContext);

  useEffect(() => {
    const checkLoggedUser = async () => {
      const storedAuthUser = AuthService.instance().currentUser;
      if(storedAuthUser && setIsLoggedIn){
        setIsLoggedIn(!!storedAuthUser);
      }
      setLoading(false);
    };

    checkLoggedUser();
  }, []);

  useEffect(() => {
    AuthService.instance().userChangeHandler = handleUserChange;
  }, []);

  function handleUserChange(user: AuthUser | undefined) {
    if (setIsLoggedIn) {
      setIsLoggedIn(!!user);
    }
  }

  useEffect(() => {
    const authUser = AuthService.instance().currentUser;

    if (!authUser) {
      setToken(null);
    }

    if (authUser){
      setToken(authUser.token);
    }
  }, [ isLoggedIn ]);

  return [ token, loading ];
};

export default AuthRoutes;

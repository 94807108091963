import { makeStyles } from '@material-ui/core';
import R from 'res/R';
import {
  CremaTheme,
} from 'services/context/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    container: {
      maxHeight: '100vh',
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      width: '19rem',
      [theme.breakpoints.up('xl')]: {
        width: '22.8rem',
      },
    },
    drawerContainer: {
      maxHeight: '100vh',
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      width: '19rem',
      [theme.breakpoints.up('xl')]: {
        width: '21.6rem',
      },
    },
    sidebarBg: {
      overflow: 'hidden',
      backgroundColor: R.colors.virbacBrown,
    },
    scrollAppSidebar: {
      paddingTop: 0,
      paddingBottom: 8,
      height: 'calc(100vh - 230px) !important',
      [theme.breakpoints.up('xl')]: {
        height: 'calc(100vh - 254px) !important',
      },
    },
    drawerScrollAppSidebar: {
      paddingTop: 4,
      paddingBottom: 20,
      height: 'calc(100vh - 112px) !important',
    },

    sidebarModern: {
      height: '100%',
      width: '100%',
      color: 'white',
      overflow: 'hidden',
      borderRadius: 0,
    },
    sidebarStandard: {
      height: '100%',
      width: '100%',
      color: 'white',
      overflow: 'hidden',
    },
  };
});
export default useStyles;

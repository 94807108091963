import React, { useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import UserInfo from './UserInfo';
import Navigation from '../Navigation';
import LogoutItem from '../Navigation/LogoutItem';
import Box from '@material-ui/core/Box';
import AppContext from 'services/context/AppContext';
import useStyles from './AppSidebar.style';
import AppContextPropsType from 'services/context/AppContextPropsType';
import { AuthService } from 'services/auth/authService';

interface AppSidebarProps {
  position?: 'left' | 'bottom' | 'right' | 'top' | undefined;
}

const AppSidebar: React.FC<AppSidebarProps> = ({ position = 'left' }) => {
  const { navCollapsed, toggleNavCollapsed } = useContext<AppContextPropsType>(AppContext);

  const userInfo = {
    displayName: AuthService.instance().currentUser?.displayName || '',
    email: AuthService.instance().currentUser?.email || '',
    photoURL: AuthService.instance().currentUser?.photoURL || '',
  };

  const handleToggleDrawer = () => {
    if (toggleNavCollapsed) {
      toggleNavCollapsed();
    }
  };

  const classes = useStyles();
  const sidebarClasses = classes.sidebarModern;

  const logoutItem = {
    id: 'logout',
    title: 'Log out',
    messageId: 'sidebar.app.logout',
    type: 'item',
    icon: 'exit_to_app',
  };

  return (
    <React.Fragment>
      <Hidden lgUp>
        <Drawer
          anchor={position}
          open={navCollapsed}
          onClose={() => handleToggleDrawer()}
          style={{ position: 'absolute' }}>
          <Box height='100%' className={classes.drawerContainer}>
            <Box
              height='100%'
              width='100%'
              color='primary.contrastText'
              className={classes.sidebarBg}>
              <UserInfo user={userInfo} />
              <PerfectScrollbar className={classes.drawerScrollAppSidebar}>
                <Navigation />
              </PerfectScrollbar>
              <LogoutItem item={logoutItem} level={0} />
              `
            </Box>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Box height='100%' className={classes.container}>
          <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
            <UserInfo user={userInfo} />
            <PerfectScrollbar
              className={clsx(classes.scrollAppSidebar, 'scrollAppSidebar')}>
              <Navigation />
            </PerfectScrollbar>
            <LogoutItem item={logoutItem} level={0} />
          </Box>
        </Box>
      </Hidden>
    </React.Fragment>
  );
};

export default AppSidebar;

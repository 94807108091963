import React from 'react';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import ContentView from '../ContentView';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import useStyles from './style';
import clsx from 'clsx';

interface DefaultLayoutProps {}

const DefaultLayout: React.FC<DefaultLayoutProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box
      className={clsx(
        classes.appMain,
        '',
      )}>
      <AppHeader />
      <Box className={classes.mainContent}>
        <AppSidebar />

        <Hidden mdDown>
          <Box className={classes.mainContainer}>
            <ContentView />
          </Box>
        </Hidden>

        <Hidden lgUp>
          <Box className={classes.mainContainerFull}>
            <ContentView />
          </Box>
        </Hidden>
      </Box>
    </Box>
  );
};

export default DefaultLayout;

import React from 'react';
import { RouteConfig } from 'react-router-config';

export const dashBoardConfigs: RouteConfig[] = [
  {
    auth: [ 'user' ],
    routes: [
      {
        path: '/dashboards/customerManagement/edit/:id/:countryId',
        component: React.lazy(() => import('./CustomerManagement/screens/EditCustomerScreen')),
      },
      {
        path: '/dashboards/customerManagement/create',
        component: React.lazy(() => import('./CustomerManagement/screens/CreateCustomerScreen')),
      },
      {
        path: '/dashboards/customerManagement/:id/editPond/:pondId',
        component: React.lazy(() => import('./CustomerManagement/screens/EditPondScreen')),
      },
      {
        path: '/dashboards/customerManagement/:id/createPond',
        component: React.lazy(() => import('./CustomerManagement/screens/CreatePondScreen')),
      },
      {
        path: '/dashboards/customerManagement/:id',
        component: React.lazy(() => import('./CustomerManagement/screens/CustomerDetailScreen')),
      },
      {
        path: '/dashboards/customerManagement',
        component: React.lazy(() => import('./CustomerManagement')),
      },
      
    ],
  },
  {
    auth: [ 'user' ],
    routes: [
      {
        path: '/dashboards/comparisonArchives',
        exact: true,
        component: React.lazy(() => import('./ComparisonArchives/screens/ComparisonArchiveListScreen')),
      },
      {
        path: '/dashboards/comparisonArchives/details/:id',
        component: React.lazy(() => import('./ComparisonArchives/screens/ComparisonArchiveDetailsScreen')),
      },
    ],
  },
  {
    auth: [ 'user' ],
    routes: [
      {
        path: '/dashboards/productManagement/:company/editProduct/:id',
        component: React.lazy(() => import('./ProductManagement/screens/EditProductScreen')),
      },
      {
        path: '/dashboards/productManagement/:company/createProduct',
        component: React.lazy(() => import('./ProductManagement/screens/CreateProductScreen')),
      },
      {
        path: '/dashboards/productManagement/:id',
        component: React.lazy(() => import('./ProductManagement/screens/ProductDetailScreen')),
      },
      {
        path: '/dashboards/productManagement',
        component: React.lazy(() => import('./ProductManagement')),
      },
    ],
  },
  {
    auth: [ 'user' ],
    routes: [
      {
        path: '/dashboards/countryManagement/edit/:id',
        component: React.lazy(() => import('./CountryManagement/screens/EditCountryScreen')),
      },
      {
        path: '/dashboards/countryManagement/create',
        component: React.lazy(() => import('./CountryManagement/screens/CreateCountryScreen')),
      },
      {
        path: '/dashboards/countryManagement',
        component: React.lazy(() => import('./CountryManagement')),
      },
    ],
  },
  {
    auth: [ 'user' ],
    routes: [
      {
        path: '/dashboards/userAccountManagement/edit/:id',
        component: React.lazy(() => import('./UserAccountManagement/screens/EditUserScreen')),
      },
      {
        path: '/dashboards/userAccountManagement/create',
        component: React.lazy(() => import('./UserAccountManagement/screens/CreateUserScreen')),
      },
      {
        path: '/dashboards/userAccountManagement',
        component: React.lazy(() => import('./UserAccountManagement')),
      },
    ],
  },
  {
    auth: [ 'user' ],
    routes: [
      {
        path: '/dashboards/scenarioTemplates/edit/:id',
        component: React.lazy(() => import('./ScenarioTemplates/screens/EditScenarioTemplate')),
      },
      {
        path: '/dashboards/scenarioTemplates/create',
        component: React.lazy(() => import('./ScenarioTemplates/screens/CreateScenarioTemplate')),
      },
      {
        path: '/dashboards/scenarioTemplates/:id',
        component: React.lazy(() => import('./ScenarioTemplates/screens/ScenarioTemplateDetail')),
      },
      {
        path: '/dashboards/scenarioTemplates',
        component: React.lazy(() => import('./ScenarioTemplates')),
      },
    ],
  },  
];

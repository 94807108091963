import React, { ReactNode } from 'react';
import { FooterRef } from 'components/footer/ref';

const config: {
  ref: React.RefObject<FooterRef>;
} = {
  ref: React.createRef<FooterRef>(),
};

const show = (children: ReactNode) => {
  config.ref.current?.show(children);
};

const hide = () => {
  config.ref.current?.hide();
};

const isShowing = () => {
  return config.ref.current?.isShowing();
};

export default {
  show,
  hide,
  isShowing,
  ref: config.ref,
};
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { CremaTheme } from 'services/context/AppContextPropsType';
import R from 'res/R';

type UserInfoProps = {
  user: {
    displayName: string;
    email: string;
    photoURL: string;
  };
}

const UserInfo: React.FC<UserInfoProps> = (props) => {
  const { user } = props;

  const getUserAvatar = () => {
    if (user && user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user && user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  const useStyles = makeStyles((theme: CremaTheme) => {
    return {
      crUserInfo: {
        backgroundColor: R.colors.virbacBrown,
        paddingTop: 9,
        paddingBottom: 9,
        minHeight: 56,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
          paddingTop: 10,
          paddingBottom: 10,
          minHeight: 70,
        },
      },
      profilePic: {
        height: 40,
        width: 40,
        backgroundColor: R.colors.virbacLightBrown,
        [theme.breakpoints.up('xl')]: {
          height: 45,
          width: 45,
        },
      },
      userInfo: {
        width: 'calc(100% - 75px)',
      },
      userName: {
        ...R.typography.adminName,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: R.colors.white,
        fontSize: 18,
        [theme.breakpoints.up('xl')]: {
          fontSize: 20,
        },
      },
      designation: {
        ...R.typography.search,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: R.colors.virbacLightBrown,
      },
      pointer: {
        cursor: 'pointer',
      },
      bottomLine: {
        paddingLeft: 9,
        paddingRight: 9,
        marginTop: 10,
        borderBottom: '1px solid #A79C97',
      },
    };
  });

  const classes = useStyles(props);

  return (
    <Box
      px={{ xs: 4, xl: 7 }}
      className={clsx(classes.crUserInfo, 'cr-user-info')}>
      <Box display='flex' alignItems='center'>
        {user && user.photoURL ? (
          <Avatar className={classes.profilePic} src={user.photoURL} />
        ) : (
          <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
        )}
        <Box ml={4} className={clsx(classes.userInfo, 'user-info')}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'>
            <Box mb={0} className={clsx(classes.userName)}>
              {user && (user.displayName ? user.displayName : 'Admin User')}
            </Box>
          </Box>
          <Box className={classes.designation}>
            Admin Manager
          </Box>
        </Box>
      </Box>
      <Box className={clsx(classes.bottomLine, 'user-info-bottom-line')}></Box>
    </Box>
  );
};

export default UserInfo;

import { NavItemProps } from 'navigators/Navigation/NavItemProps';

const routesConfig: NavItemProps[] = [
  {
    id: 'customerManagement',
    title: 'Customer Management',
    messageId: 'sidebar.app.customerManagement',
    type: 'item',
    icon: 'supervisor_account',
    url: '/dashboards/customerManagement',
  },
  {
    id: 'comparisonArchives',
    title: 'Scenario Comparison Archives',
    messageId: 'sidebar.app.scenarioComparisonArchives',
    type: 'item',
    icon: 'archive',
    url: '/dashboards/comparisonArchives',
  },
  {
    id: 'scenarioTemplate',
    title: 'Scenario templates',
    messageId: 'sidebar.app.scenarioTemplate',
    type: 'item',
    icon: 'date_range',
    url: '/dashboards/scenarioTemplates',
  },
  {
    id: 'productManagement',
    title: 'Product Management',
    messageId: 'sidebar.app.productManagement',
    type: 'item',
    icon: 'category',
    url: '/dashboards/productManagement',
  },
  {
    id: 'countryManagement',
    title: 'Country Management',
    messageId: 'sidebar.app.countryManagement',
    type: 'item',
    icon: 'language',
    url: '/dashboards/countryManagement',
  },
  {
    id: 'userAccountManagement',
    title: 'User Account Management',
    messageId: 'sidebar.app.userAccountManagement',
    type: 'item',
    icon: 'account_circle',
    url: '/dashboards/userAccountManagement',
  },
];

export const initialUrl = '/dashboards/customerManagement'; // this url will open after login

export default routesConfig;

import { createStyles, withStyles } from '@material-ui/core';
import { Classes } from 'infra/material/classes';
import React, { useImperativeHandle, useState } from 'react';
import R from 'res/R';
import { FooterRef } from './ref';

type FooterProps = {
  classes: Classes<typeof classNames>;
  className?: string;
}

type PropsWithForwardedRef = FooterProps & {
  myForwardedRef: React.Ref<FooterRef>;
}

const Footer: React.FC<PropsWithForwardedRef> = (props) => {
  const { classes, className } = props;
  const [ children, setChildren ] = useState<React.ReactNode>();

  useImperativeHandle(props.myForwardedRef, () => ({
    show: (children: React.ReactNode) => {
      setChildren(children);
    },
    hide: () => setChildren(undefined),
    isShowing: () => !!children,
  }));

  if (!children) {
    return null;
  }

  return (
    <div className={[ classes.footer, className ].join(' ')}>
      {children}
    </div>
  );
};

const classNames = createStyles({
  footer: {
    position: 'absolute',
    zIndex: 1000000,
    bottom: 0,
    right: 0,
    backgroundColor: R.colors.white,
    boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
  },
});

const WithStylesFooter = withStyles(classNames)(Footer);
// eslint-disable-next-line react/display-name
export default React.forwardRef((props: Omit<FooterProps, 'classes'>, ref: React.Ref<FooterRef>) => <WithStylesFooter {...props} myForwardedRef={ref} />);
import { Role, Authentication } from 'vapp_shared/api/cms/models';
import { HttpRequest } from 'infra/http/httpRequest';
import { HttpVerb } from 'infra/http/type';
import { AuthUser } from './authUser';

const LOGGED_IN_USER_KEY = 'AUTH_SERVICE_LOGGED_IN_USER_KEY';

export class AuthService {
  private _currentUser: AuthUser | undefined;
  private _userChangeHandler: (user: AuthUser | undefined) => void;

  private constructor() {
    const userStr = localStorage.getItem(LOGGED_IN_USER_KEY);
    if (userStr) {
      this._currentUser = JSON.parse(userStr);
    }
  }

  private static _instance: AuthService;
  public static instance(): AuthService {
    if (!this._instance) {
      this._instance = new AuthService();
    }

    return this._instance;
  }

  public get currentUser(): AuthUser | undefined {
    return this._currentUser;
  }

  public set currentUser(user: AuthUser | undefined) {
    this._currentUser = user;

    if (user) {
      localStorage.setItem(LOGGED_IN_USER_KEY, JSON.stringify(user));
    } else {
      localStorage.removeItem(LOGGED_IN_USER_KEY);
    }

    if (this._userChangeHandler) {
      this._userChangeHandler(this._currentUser);
    }
  }

  public set userChangeHandler(handler: (user: AuthUser | undefined) => void) {
    this._userChangeHandler = handler;
  }

  public get userChangeHandler(): (user: AuthUser | undefined) => void {
    return this._userChangeHandler;
  }

  public async getAllRoles(): Promise<Role[] | undefined> {
    const api = HttpRequest.create(HttpVerb.Get, 'api/user/allRoles');

    return await api.execute<Role[]>(this._currentUser?.token);
  }

  public async authenticate(googleResponse: any): Promise<AuthUser | undefined>{
    const api = HttpRequest.create(HttpVerb.Post, 'api/auth/');
    api.params({ token: googleResponse.tokenId });

    const result = await api.execute<Authentication>();

    if(!result){
      return undefined;
    }

    const user = result.user;
    const googleUserInfo = googleResponse.profileObj;
    const authUserInfo: AuthUser = {
      uid: user.id,
      displayName: user.name,
      email: user.mailAddress ? user.mailAddress : '',
      photoURL: googleUserInfo.imageUrl,
      token: result.token,
    };
    this.currentUser = authUserInfo;
 
    return authUserInfo;
  }

  public signOut() {
    // TODO Google logout https://sioux.atlassian.net/browse/VAPP-731
    this.currentUser = undefined;
  }
}
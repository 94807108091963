import React from 'react';

export const versionRouteConfig = [
  {
    routes: [
      {
        path: '/version',
        component: React.lazy(() => import('./Version')),
      },
    ],
  },
];

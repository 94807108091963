import 'reflect-metadata';
import React from 'react';
import Root from 'navigators/Root';
import AuthRoutes from 'services/auth/AuthRoutes';
import LocaleProvider from 'services/localizer/LocaleProvider';
import CremaThemeProvider from 'services/theme/CremaThemeProvider';
import CremaStyleProvider from 'services/theme/CremaStyleProvider';
import ContextProvider from 'services/context/ContextProvider';

import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter } from 'react-router-dom';

const App = () => (
  <ContextProvider>
    <CremaThemeProvider>
      <CremaStyleProvider>
        <LocaleProvider>
          <BrowserRouter>
            <AuthRoutes>
              <CssBaseline />
              <Root />
            </AuthRoutes>
          </BrowserRouter>
        </LocaleProvider>
      </CremaStyleProvider>
    </CremaThemeProvider>
  </ContextProvider>
);

export default App;

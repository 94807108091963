const colors = {
  virbacBrown: '#514237',
  virbacLightBrown: '#A79C97',
  virbacBlue: '#004D97',
  dark: '#000000',
  checkSelect: '#F6A609',
  error: '#E02020',
  white: '#FFFFFF',
  search: '#F4F2F2',
  secondaryBlack: '#5A5F64',
  grayLabelText: '#A0A4A8',
  lightGrayHeaderLine: '#DBDDE0',
  cardLine: '#E8E8E8',
  black: '#3A3A3A',
  transparent: 'transparent',
  antibiotic: '#925E2C',
  bioremediation: '#A1BE64',
  disinfectant: '#509692',
  feedProbiotic: '#D8BB33',
  nutraceutical: '#F29527',
  parasiticide: '#E64453',
  speciality: '#9F3E74',
  blueLight: '#E5EDF4',
};

export default colors;
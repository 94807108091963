import React, { ReactNode } from 'react';
import { renderRoutes } from 'react-router-config';
import Suspense from 'components/Suspense';
import routes from 'features';
import Scrollbar from 'components/Scrollbar';
import Box from '@material-ui/core/Box';

interface ContentViewProps {
  children?: ReactNode;
}

const ContentView: React.FC<ContentViewProps> = () => {
  return (
    <Scrollbar>
      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        className='main-content-view'>
        <Suspense>
          {renderRoutes(routes)}
        </Suspense>
      </Box>
    </Scrollbar>
  );
};

export default ContentView;


import colors from './colors';
import images from './images';
import typography from './typography';
import dimens from './dimens';

const R = {
  colors,
  images,
  typography,
  dimens,
};

export default R;
import React, { useContext } from 'react';
import { IntlProvider } from 'react-intl';

import AppLocale from './localization';
import PropTypes from 'prop-types';
import AppContext from 'services/context/AppContext';
import AppContextPropsType from 'services/context/AppContextPropsType';

const LocaleProvider = (props: any) => {
  const { locale } = useContext<AppContextPropsType>(AppContext);
  const currentAppLocale = AppLocale[locale.locale];

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      defaultLocale='en'
      messages={currentAppLocale.messages}>
      {props.children}
    </IntlProvider>
  );
};

export default LocaleProvider;

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const boxShadow = {
  boxShadow: '0px 4px 8px rgba(219, 221, 224, 0.7)',
} as React.CSSProperties;

const borderRadius = {
  borderRadius: '8px',
} as React.CSSProperties;

export default {
  boxShadow,
  borderRadius,
};
const images = {
  auth_background: require('./auth-background.jpg'),
  avatar_A1: require('./avatar/A1.jpg'),
  avatar_A10: require('./avatar/A10.jpg'),
  avatar_A11: require('./avatar/A11.jpg'),
  avatar_A12: require('./avatar/A12.jpg'),
  avatar_A13: require('./avatar/A13.jpg'),
  avatar_A14: require('./avatar/A14.jpg'),
  avatar_A15: require('./avatar/A15.jpg'),
  avatar_A16: require('./avatar/A16.jpg'),
  avatar_A17: require('./avatar/A17.jpg'),
  avatar_A18: require('./avatar/A18.jpg'),
  avatar_A19: require('./avatar/A19.jpg'),
  avatar_A2: require('./avatar/A2.jpg'),
  avatar_A20: require('./avatar/A20.jpg'),
  avatar_A21: require('./avatar/A21.jpg'),
  avatar_A22: require('./avatar/A22.jpg'),
  avatar_A23: require('./avatar/A23.jpg'),
  avatar_A24: require('./avatar/A24.jpg'),
  avatar_A25: require('./avatar/A25.jpg'),
  avatar_A26: require('./avatar/A26.jpg'),
  avatar_A27: require('./avatar/A27.jpg'),
  avatar_A28: require('./avatar/A28.jpg'),
  avatar_A29: require('./avatar/A29.jpg'),
  avatar_A3: require('./avatar/A3.jpg'),
  avatar_A30: require('./avatar/A30.jpg'),
  avatar_A4: require('./avatar/A4.jpg'),
  avatar_A5: require('./avatar/A5.jpg'),
  avatar_A6: require('./avatar/A6.jpg'),
  avatar_A7: require('./avatar/A7.jpg'),
  avatar_A8: require('./avatar/A8.jpg'),
  avatar_A9: require('./avatar/A9.jpg'),
  delete: require('./delete.png'),
  edit: require('./edit.png'),
  errorPageImages_404: require('./errorPageImages/404.png'),
  errorPageImages_500: require('./errorPageImages/500.png'),
  errorPageImages_comingsoon: require('./errorPageImages/comingsoon.png'),
  errorPageImages_maintenance: require('./errorPageImages/maintenance.png'),
  layouts_boxed: require('./layouts/boxed.png'),
  layouts_container: require('./layouts/container.png'),
  layouts_full_width: require('./layouts/full width.png'),
  logo_icon_large: require('./logo-icon-large.png'),
  logo_white_with_name: require('./logo-white-with-name.png'),
  navigationStyle_nav1: require('./navigationStyle/nav1.png'),
  navigationStyle_nav10: require('./navigationStyle/nav10.png'),
  navigationStyle_nav2: require('./navigationStyle/nav2.png'),
  navigationStyle_nav3: require('./navigationStyle/nav3.png'),
  navigationStyle_nav4: require('./navigationStyle/nav4.png'),
  navigationStyle_nav5: require('./navigationStyle/nav5.png'),
  navigationStyle_nav6: require('./navigationStyle/nav6.png'),
  navigationStyle_nav7: require('./navigationStyle/nav7.png'),
  navigationStyle_nav8: require('./navigationStyle/nav8.png'),
  navigationStyle_nav9: require('./navigationStyle/nav9.png'),
  virbac_logo: require('./virbac-logo.png'),
};
export default images;

export const createRoutes = (routeConfigs: any[]) => {
  let allRoutes: any[] = [];
  routeConfigs.forEach((config) => {
    allRoutes = [ ...allRoutes, ...setRoutes(config) ];
  });
  return allRoutes;
};

export const setRoutes = (config: any) => {
  let routes = [ ...config.routes ];
  if (config.auth) {
    routes = routes.map((route) => {
      const auth = route.auth
        ? [ ...config.auth, ...route.auth ]
        : [ ...config.auth ];
      return { ...route, auth };
    });
  }

  return [ ...routes ];
};
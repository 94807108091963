import React, { useState } from 'react';
import defaultConfig from './defaultConfig';
import AppContext from './AppContext';
import PropTypes from 'prop-types';
import routes from 'features';

const ContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [ theme, updateTheme ] = useState(defaultConfig.theme);
  const [ locale, changeLocale ] = useState(defaultConfig.locale);
  const [ isLoggedIn, setIsLoggedIn ] = useState<boolean>(false);
  const [ initialPath, setInitialPath ] = useState<string>('/');
  const [ navCollapsed, setNavCollapsed ] = useState<boolean>(false);

  const [ primary, updatePrimaryColor ] = useState(
    defaultConfig.theme.palette.primary.main,
  );
  const [ sidebarColor, updateSidebarColor ] = useState(
    defaultConfig.theme.palette.sidebar.bgColor,
  );
  const [ secondary, updateSecondaryColor ] = useState(
    defaultConfig.theme.palette.secondary.main,
  );

  return (
    <AppContext.Provider
      value={{
        theme,
        primary,
        secondary,
        locale,
        routes,
        sidebarColor,
        updateSidebarColor,
        updateTheme,
        updatePrimaryColor,
        updateSecondaryColor,
        changeLocale,
        isLoggedIn,
        setIsLoggedIn,
        initialPath,
        setInitialPath,
        navCollapsed,
        toggleNavCollapsed: () => setNavCollapsed(!navCollapsed),
      }}>
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
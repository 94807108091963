import colors from './colors';

const typography = {
  bigTitle: {
    fontFamily: 'Verdana',
    fontSize: '25px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: colors.black,
  } as React.CSSProperties,

  title: {
    fontFamily: 'Verdana',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: colors.black,
  } as React.CSSProperties,

  normal: {
    fontFamily: 'Verdana',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: colors.secondaryBlack,
  } as React.CSSProperties,

  label: {
    fontFamily: 'Verdana',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: colors.grayLabelText,
  } as React.CSSProperties,

  header: {
    fontFamily: 'Verdana',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: colors.black,
  } as React.CSSProperties,

  filledText: {
    fontFamily: 'Verdana',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: colors.black,
  } as React.CSSProperties,

  archivedPondName: {
    fontFamily: 'Verdana',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    color: colors.black,
    textDecoration: 'underline',
  } as React.CSSProperties,

  adminName: {
    fontFamily: 'Verdana',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    color: colors.black,    
  } as React.CSSProperties,

  search: {
    fontFamily: 'Verdana',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    color: colors.grayLabelText,
  } as React.CSSProperties,

  objectiveBodyText: {
    fontFamily: 'Verdana',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    color: colors.secondaryBlack,
  } as React.CSSProperties,

  popupText: {
    fontFamily: 'Verdana',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    color: colors.black,
  } as React.CSSProperties,

  buttonCardName: {
    fontFamily: 'Verdana',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    color: colors.black,
  } as React.CSSProperties,
};

export default typography;

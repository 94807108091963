import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Layout from './Layout';
import ContentView from './ContentView';
import Footer from 'components/footer';
import footerManager from 'infra/footerManager';
import AppContextPropsType from 'services/context/AppContextPropsType';
import AppContext from 'services/context/AppContext';

const useStyle = makeStyles((theme: Theme) => ({
  appAuth: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '100vh',
    backgroundColor: '#f3f4f6',
    backgroundSize: 'cover',

    '& .scrollbar-container': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .main-content-view': {
      padding: 20,
    },
    '& .footer': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  footer: {
    [theme.breakpoints.up('lg')]: {
      left: '19rem',
    },
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
}));

interface CremaLayoutProps {}

const CremaLayout: React.FC<CremaLayoutProps> = () => {
  const { isLoggedIn } = useContext<AppContextPropsType>(AppContext);
  const classes = useStyle();

  return (
    <>
      {isLoggedIn ? (
        <>
          <Layout />
          <Footer className={classes.footer} ref={footerManager.ref}/>
        </>
      ) : (
        <Box className={classes.appAuth}>
          <ContentView />
        </Box>
      )}
    </>
  );
};

export default React.memo(CremaLayout);
import { 
  HttpVerb,
  AnyObject,
} from './type';
import { Response as APIResponse } from 'vapp_shared/api/responses';
import { AuthService } from 'services/auth/authService';

const TAG = 'HTTP_REQUEST';

interface IHttpRequest{
  params(params: AnyObject): IHttpRequest;
  query(query: URLSearchParams): IHttpRequest;
  execute<T>(token?: string): Promise<T>;
}

export class HttpRequest implements IHttpRequest {
  private _verb?: HttpVerb;
  private _path?: string;
  private _query?: URLSearchParams ;
  private _params?: AnyObject;
  private _apiHost?: string;

  private constructor(httpVerb: HttpVerb, path: string, host?: string) {
    this._verb = httpVerb;
    this._path = path;
    this._apiHost = host;
  }

  public static create(httpVerb: HttpVerb, path: string, host?: string): IHttpRequest {
    return new HttpRequest(httpVerb, path, host);
  }

  public params(params: AnyObject ) {
    this._params = params;
    return this;
  }

  public query(query: URLSearchParams ) {
    this._query = query;
    return this;
  }

  public async execute<T>(): Promise<T> {
    if (!this._verb || !this._path) {
      throw new Error('Verb and path are required');
    }

    const restPath: string = this._path;

    return this.callFetch<T>(restPath, this._verb, this._params, this._query);
  }

  private async callFetch<T>(restPath: string, method: HttpVerb, params: any, query?: URLSearchParams) {
    const apiHost = this._apiHost || process.env.REACT_APP_API_HOST;
    const url = new URL(restPath, apiHost);
    const token = AuthService.instance().currentUser?.token;

    if( ((method === HttpVerb.Get) || (method === HttpVerb.Delete)) && query){
      query?.forEach( (key, value) => url.searchParams.append(value, key));
    }

    console.info(`${TAG} - ${method} at ${url}`);

    const res = await fetch(url.href, {
      method: method.toString(),
      headers: new Headers({ 
        'content-type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'bearer ' + token,
      }),
      mode: 'cors',
      body: params ? JSON.stringify(params) : null,
    });

    console.info(`${TAG} - status: ${res.status}`);

    const apiResponse = await (this.isJsonResponse(res) ? res.json() : res.text()) as APIResponse<T>;

    if(apiResponse.failures && apiResponse.failures.length > 0){
      //TODO this will be handled in Error handling task
      throw new Error(apiResponse.failures[0].message);
    }

    return apiResponse.payload as T;
  }

  private isJsonResponse (res: Response) {
    const contentType = res.headers.get('content-type');
    return contentType && contentType.indexOf('application/json') !== -1;
  }
}
import { makeStyles } from '@material-ui/styles';
import {
  CremaTheme,
} from 'services/context/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    navItem: {
      height: 40,
      paddingLeft:
        theme.direction === 'ltr'
          ? (props: {level: number}) => 17 + 50 * props.level
          : 12,
      paddingRight:
        theme.direction === 'rtl'
          ? (props: {level: number}) => 17 + 50 * props.level
          : 12,
      color: theme.palette.text.hint,
      fontWeight: 700,
      fontSize: 14,
      cursor: 'pointer',
      textDecoration: 'none!important',

      [theme.breakpoints.up('xl')]: {
        height: 45,
        fontSize: 16,
        paddingLeft:
          theme.direction === 'ltr'
            ? (props: {level: number}) => 24 + 50 * props.level
            : 12,
        paddingRight:
          theme.direction === 'rtl'
            ? (props: {level: number}) => 24 + 50 * props.level
            : 12,
      },
      '&.nav-item-header': {
        textTransform: 'uppercase',
      },
    },
  };
});

export default useStyles;

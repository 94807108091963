import { LanguageProps } from '../LanguageProps';

const languageData: LanguageProps[] = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
];
export default languageData;

import React from 'react';
import { Redirect } from 'react-router-dom';

import { createRoutes } from 'navigators/utils';
import { dashBoardConfigs } from './dashboard';
import { errorPagesConfigs } from './errorPages';
import { authRouteConfig } from './auth';
import { versionRouteConfig } from './version';
import { initialUrl } from './routesConfig';

const routeConfigs = [
  ...authRouteConfig,
  ...dashBoardConfigs,
  ...versionRouteConfig,
  ...errorPagesConfigs,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
